<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-6">
          <h3>{{ purchasesDelivery.code }}</h3>
        </div>

        <div class="col-6 text-right">
          <base-button
            class="btn-outline-danger"
            icon
            size="sm"
            v-if="
              purchasesDelivery.status === DELIVERY_STATUS_DRAFT &&
              $currentUserCan($permissions.PERM_DELETE_PURCHASES_DELIVERIES)
            "
            @click="deletePurchasesDelivery"
          >
            <span class="btn-inner--icon">
              <i class="far fa-trash-alt"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.DELETE") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="
              purchasesDelivery.status === DELIVERY_STATUS_DRAFT &&
              $currentUserCan($permissions.PERM_EDIT_PURCHASES_DELIVERIES)
            "
            @click="editPurchasesDelivery"
          >
            <span class="btn-inner--icon">
              <i class="far fa-edit"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.EDIT") }}
            </span>
          </base-button>
        </div>
      </div>
    </div>

    <div class="all-infos">
      <

      <div class="all-infos-text">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ purchasesDelivery.created_at | moment("LLLL") }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("PURCHASES_DELIVERIES.EXPIRATION_TIME") }}
          </dt>
          <dd class="col-sm-8">
            {{ purchasesDelivery.expiration_time | moment("LLLL") }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.LOCATIONS") }}</dt>
          <dd class="col-sm-8">
            <locations :locations="purchasesDelivery.allowedLocations" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd class="col-sm-8">
            <organization :organization="purchasesDelivery.organization" />
          </dd>
        </dl>

        <dl class="row" v-if="purchasesDelivery.purchasesOrder">
          <dt class="col-sm-4">{{ $t("COMMON.PURCHASES_ORDER") }}</dt>
          <dd class="col-sm-8">
            <object-link :object="purchasesDelivery.purchasesOrder" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ISSUER") }}</dt>
          <dd class="col-sm-8">
            <object-link :object="purchasesDelivery.issuer" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.STATUS") }}</dt>
          <dd class="col-sm-8">
            <purchases-delivery-status-badge
              :purchasesDelivery="purchasesDelivery"
            />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4" v-if="purchasesDelivery.excerpt">
            {{ $t("COMMON.NOTE") }}
          </dt>
          <dd class="col-sm-8" v-if="purchasesDelivery.excerpt">
            <div v-html="purchasesDelivery.excerpt"></div>
          </dd>
        </dl>
      </div>
    </div>

    <h3 class="mb-4">{{ purchasesDelivery.code }}</h3>
    <div class="col-12"></div>
  </div>
</template>

<script>
import PurchasesDeliveryStatusBadge from "./PurchasesDeliveryStatusBadge.vue";
import { DELIVERY_STATUS_DRAFT } from "@/constants/deliveries";

export default {
  name: "purchases-delivery-view-global",

  components: {
    PurchasesDeliveryStatusBadge,
  },

  props: ["purchasesDelivery"],

  data() {
    return {
      DELIVERY_STATUS_DRAFT,
    };
  },

  computed: {},

  created() {},

  methods: {
    editPurchasesDelivery() {
      this.$emit("onEditPurchasesDelivery", this.purchasesDelivery);
    },
    deletePurchasesDelivery() {
      this.$emit("onDeletePurchasesDelivery", this.purchasesDelivery);
    },
  },

  mounted() {},

  watch: {
    purchasesDelivery(purchasesDelivery) {},
  },
};
</script>

<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">
              {{ $t("PURCHASES_DELIVERIES.PURCHASES_DELIVERIES_LIST") }}
            </h3>
          </div>
          <div class="col-6 text-right">
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openPurchasesDeliveryCreateModal"
              v-if="
                $currentUserCan($permissions.PERM_CREATE_PURCHASES_DELIVERIES)
              "
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">
                {{ $t("PURCHASES_DELIVERIES.ADD_PURCHASES_DELIVERY") }}
              </span>
            </base-button>
            <notification-subscription
              v-if="
                $currentUserCan($permissions.PERM_VIEW_PURCHASES_DELIVERIES)
              "
              :objectType="'PurchasesDeliveries'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />
          </div>
        </div>
      </div>

      <purchases-delivery-list-table
        @onViewPurchasesDelivery="openPurchasesDeliveryViewModal"
        @onEditPurchasesDelivery="openPurchasesDeliveryEditModal"
        @onDeletePurchasesDelivery="deletePurchasesDelivery"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewPurchasesDeliveryModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewPurchasesDeliveryModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'PURCHASES_DELIVERY_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("PURCHASES_DELIVERIES.VIEW_PURCHASES_DELIVERY") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-button
                type="primary"
                icon
                size="sm"
                @click="
                  () => {
                    purchasesDeliveryValidateFormModalOpened = true;
                  }
                "
                v-if="
                  openPurchasesDeliveryLoaded
                    ? openPurchasesDelivery.items.length > 0 &&
                      openPurchasesDelivery.status === DELIVERY_STATUS_DRAFT
                    : false
                "
                :title="$t('PURCHASES_DELIVERIES.VALIDATE')"
              >
                <span class="btn-inner--icon">
                  <i class="fal fa-check"></i>
                </span>
              </base-button>

              <base-button
                type="primary"
                icon
                size="sm"
                @click="viewPurchasesOrder(openPurchasesDelivery)"
                v-if="
                  openPurchasesDeliveryLoaded
                    ? openPurchasesDelivery.purchasesOrder
                    : false
                "
                :title="$t('PURCHASES_DELIVERIES.VIEW_ORDER')"
              >
                <span class="btn-inner--icon">
                  <i class="ni ni-bag-17"></i>
                </span>
              </base-button>

              <base-button
                type="danger"
                icon
                size="sm"
                @click="cancelPurchasesDelivery(openPurchasesDelivery)"
                v-if="canCancelPurchasesDelivery"
                :title="$t('PURCHASES_DELIVERIES.CANCEL')"
              >
                <span class="btn-inner--icon">
                  <i class="ni ni-fat-remove"></i>
                </span>
              </base-button>

              <notification-subscription
                v-if="openPurchasesDelivery"
                :objectType="'PurchasesDeliveries'"
                :objectId="openPurchasesDelivery.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />

              <button class="close" @click="closePurchasesDeliveryModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>

          <div class="resizable-wrapper-content-body">
            <view-purchases-delivery-page
              v-if="openPurchasesDelivery"
              :purchasesDeliveryId="openPurchasesDelivery.id"
              @purchasesDeliveryLoaded="
                (purchasesDelivery) => {
                  openPurchasesDelivery = purchasesDelivery;
                  openPurchasesDeliveryLoaded = true;
                }
              "
              @onEditPurchasesDelivery="openPurchasesDeliveryEditModal"
              @onDeletePurchasesDelivery="deletePurchasesDelivery"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditPurchasesDeliveryModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditPurchasesDeliveryModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'PURCHASES_DELIVERY'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("PURCHASES_DELIVERIES.EDIT_PURCHASES_DELIVERY") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  class="view"
                  @click="openPurchasesDeliveryViewModal(openPurchasesDelivery)"
                >
                  <i class="fal fa-eye"></i>
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closePurchasesDeliveryModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-purchases-delivery-page
              v-if="openPurchasesDelivery"
              :purchasesDeliveryId="openPurchasesDelivery.id"
              @onViewPurchasesDelivery="openPurchasesDeliveryViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddPurchasesDeliveryModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddPurchasesDeliveryModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'PURCHASES_DELIVERY'"
        >
          <div class="resizable-wrapper-content-header">
            <h1>
              {{ $t("PURCHASES_DELIVERIES.ADD_PURCHASES_DELIVERY") }}
            </h1>
            <button class="close" @click="closePurchasesDeliveryModal">
              <i class="fal fa-times"></i>
            </button>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-purchases-delivery-page
              @onViewPurchasesDelivery="openPurchasesDeliveryViewModal"
            />
          </div>
        </div>
      </div>
    </div>

    <purchases-delivery-view-validate-delivery-form
      v-if="canValidatePurchasesDelivery"
      :purchasesDelivery="openPurchasesDelivery"
      :showModal="purchasesDeliveryValidateFormModalOpened"
      @closePurchasesOrderDeliveryForm="
        () => {
          purchasesDeliveryValidateFormModalOpened = false;
        }
      "
      @purchasesDeliveryUpdated="
        () => {
          renderKey++;
        }
      "
    />
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  DELIVERY_STATUS_CANCELED,
  DELIVERY_STATUS_DRAFT,
  DELIVERY_STATUS_VALIDATED,
} from "@/constants/deliveries";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import PurchasesDeliveryListTable from "./partials/PurchasesDeliveryListTable.vue";
import EditPurchasesDeliveryPage from "./components/EditPurchasesDeliveryComponent.vue";
import AddPurchasesDeliveryPage from "./components/AddPurchasesDeliveryComponent.vue";
import ViewPurchasesDeliveryPage from "./components/ViewPurchasesDeliveryComponent.vue";
import PurchasesDeliveryViewValidateDeliveryForm from "./partials/PurchasesDeliveryViewValidateDeliveryForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    PurchasesDeliveryListTable,
    NotificationSubscription,
    EditPurchasesDeliveryPage,
    AddPurchasesDeliveryPage,
    ViewPurchasesDeliveryPage,
    [Button.name]: Button,
    PurchasesDeliveryViewValidateDeliveryForm,
  },

  mixins: [requestErrorMixin],

  computed: {
    canCancelPurchasesDelivery() {
      if (
        this.openPurchasesDelivery.status === DELIVERY_STATUS_CANCELED ||
        this.openPurchasesDelivery.status === DELIVERY_STATUS_DRAFT
      ) {
        return false;
      }
      if (this.openPurchasesDelivery.status === DELIVERY_STATUS_VALIDATED) {
        return true;
      }
      return false;
    },

    canValidatePurchasesDelivery() {
      if (!this.openPurchasesDeliveryLoaded) {
        return false;
      }
      if (
        this.openPurchasesDelivery.items.length > 0 &&
        this.openPurchasesDelivery.status === DELIVERY_STATUS_DRAFT
      ) {
        return true;
      }
      return false;
    },
  },

  watch: {},

  data() {
    const locationId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewPurchasesDeliveryModalOpened = false;
    let isEditPurchasesDeliveryModalOpened = false;
    let isAddPurchasesDeliveryModalOpened = false;
    let openPurchasesDelivery = null;
    if (locationId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewPurchasesDeliveryModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditPurchasesDeliveryModalOpened = true;
      }
      openPurchasesDelivery = { id: locationId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddPurchasesDeliveryModalOpened = true;
    }
    return {
      isViewPurchasesDeliveryModalOpened: isViewPurchasesDeliveryModalOpened,
      isEditPurchasesDeliveryModalOpened: isEditPurchasesDeliveryModalOpened,
      isAddPurchasesDeliveryModalOpened: isAddPurchasesDeliveryModalOpened,
      openPurchasesDelivery: openPurchasesDelivery,
      openPurchasesDeliveryLoaded: false,
      renderKey: 1,
      DELIVERY_STATUS_DRAFT: DELIVERY_STATUS_DRAFT,
      DELIVERY_STATUS_CANCELED: DELIVERY_STATUS_CANCELED,
      DELIVERY_STATUS_VALIDATED: DELIVERY_STATUS_VALIDATED,
      purchasesDeliveryValidateFormModalOpened: false,
    };
  },

  methods: {
    openPurchasesDeliveryCreateModal() {
      this.closePurchasesDeliveryModal();
      this.isAddPurchasesDeliveryModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List PurchasesDeliveries",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },

    openPurchasesDeliveryViewModal(purchasesDelivery, reRender = false) {
      this.closePurchasesDeliveryModal();
      this.openPurchasesDelivery = purchasesDelivery;
      this.isViewPurchasesDeliveryModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List PurchasesDeliveries",
          query: {
            id: this.openPurchasesDelivery.id,
            action: QUERY_ACTIONS_VIEW,
          },
        }).href
      );
    },

    openPurchasesDeliveryEditModal(purchasesDelivery) {
      this.closePurchasesDeliveryModal();
      this.openPurchasesDelivery = purchasesDelivery;
      this.isEditPurchasesDeliveryModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List PurchasesDeliveries",
          query: {
            id: this.openPurchasesDelivery.id,
            action: QUERY_ACTIONS_EDIT,
          },
        }).href
      );
    },

    closePurchasesDeliveryModal() {
      this.isAddPurchasesDeliveryModalOpened = false;
      this.isViewPurchasesDeliveryModalOpened = false;
      this.isEditPurchasesDeliveryModalOpened = false;
      this.openPurchasesDeliveryLoaded = false;
      this.openPurchasesDelivery = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List PurchasesDeliveries",
          query: {},
        }).href
      );
    },

    async deletePurchasesDelivery(purchasesDelivery) {
      const confirmation = await swal.fire({
        text: this.$t("PURCHASES_DELIVERIES.DELETE_THIS_PURCHASES_DELIVERY"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch(
            "purchasesDeliveries/destroy",
            purchasesDelivery.id
          );
          this.renderKey++;
          this.closePurchasesDeliveryModal();
          this.$notify({
            type: "success",
            message: this.$t("PURCHASES_DELIVERIES.PURCHASES_DELIVERY_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async cancelPurchasesDelivery(purchasesDelivery) {
      const confirmation = await swal.fire({
        text: this.$t(
          "PURCHASES_DELIVERIES.CANCEL_PURCHASES_DELIVERY_QUESTION"
        ),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (!confirmation.value) {
        return;
      }

      try {
        await this.$store.dispatch(
          "purchasesDeliveries/cancel",
          purchasesDelivery.id
        );
        this.openPurchasesDelivery =
          this.$store.getters["purchasesDeliveries/purchasesDelivery"];
        this.renderKey++;
        this.$notify({
          type: "success",
          message: this.$t("PURCHASES_DELIVERIES.PURCHASES_DELIVERY_CANCELED"),
        });
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async viewPurchasesOrder() {
      this.$router.push(
        this.$objectViewRoute(this.purchasesDelivery.purchasesOrder)
      );
    },
  },
};
</script>
